import { render, staticRenderFns } from "./TVChannels.vue?vue&type=template&id=22ba9fbf&"
import script from "./TVChannels.vue?vue&type=script&lang=js&"
export * from "./TVChannels.vue?vue&type=script&lang=js&"
import style0 from "./TVChannels.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports