<template>
    <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <b-row>
                    <b-col>
                        <b-form-checkbox v-model="addObject.enabled" class="mt-1">Enabled</b-form-checkbox>
                    </b-col>
                    <b-col>
                        <b-form-checkbox v-model="addObject.adult" class="mt-1">Adult</b-form-checkbox>
                    </b-col>
                </b-row>

            </b-form-group>
            <b-form-group>
                <label for="name">Name:</label>
                <b-form-input
                    id="name"
                    type="text"
                    placeholder="Name"
                    v-model="addObject.name"
                />
            </b-form-group>
            <b-form-group>
                <label>Source:</label>
                <b-form-input
                    type="text"
                    placeholder="Source"
                    v-model="addObject.source"
                />
            </b-form-group>

            <b-form-group>
                <label for="categories">Categories:</label>
                <v-select
                    id="categories"
                    v-model="addObject.selected_categories"
                    :reduce="category => category.id"
                    multiple
                    label="name"
                    :options="categories"
                />

            </b-form-group>
            <b-form-group>
                <label>Logo:</label>
                <ImageSelector v-model="addObject.logo"/>
            </b-form-group>

        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="addModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addItem">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import { BButton, BModal, BFormInput, BFormGroup, BFormCheckbox, BRow, BCol } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import ImageSelector from '@/views/components/ImageSelector'

    export default {
        components: {
            ImageSelector,
            BButton,
            BModal,
            BFormInput,
            BFormCheckbox,
            BFormGroup,
            BRow,
            BCol,
            vSelect
        },
        props: {
            categories: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

                addObject: {
                    enabled: true
                },
                addModalActive: false

            }
        },
        methods: {
            open() {
                this.addModalActive = true
            },
            addItem() {
                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/tv_channel', this.addObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Item added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {}
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.$emit('itemAdded')
                })
            }
        }
    }

</script>
