<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-button variant="primary" class="mr-1" @click="$refs.addItemModal.open()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <b-button variant="primary" @click="$refs.orderModal.open()">
                    <fa icon="sort" class="mr-50 align-middle"/>
                    <span class="align-middle">Order channels</span>
                </b-button>

                <basic-table :columns="columns" :data="items" v-slot="props">
                    <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">

                        <b-button variant="success" @click="$router.push('/tv_channel/' + props.row.id)" class="mr-1">
                            <feather-icon
                                icon="EyeIcon"
                            />
                        </b-button>

                    </div>
                    <div v-else-if="props.column.displayType === 1" class="d-flex justify-content-center" style="min-width: 150px">
                        <img v-if="props.row.logo.length > 0" :src="'/api/management/v1/image/' + props.row.logo" style="max-width: 200px; max-height: 100px;" alt="Image"/>
                    </div>
                    <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                        <b-badge v-if="props.formattedRow[props.column.field] === 'Yes'" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <span v-else-if="props.column.displayType === 4" class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                    <span v-else>
                        <b>{{ props.formattedRow[props.column.field] }}</b>
                        <p style="max-height: 60px;overflow: hidden; text-overflow: ellipsis; word-wrap: break-word; display: block;">
                            {{ props.row.description }}
                        </p>
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <AddModal ref="addItemModal" :categories="categories" v-on:itemAdded="loadData"/>

        <OrderModal ref="orderModal" :value="items" :save-u-r-l="'/api/management/v1/tv_channel/set_order'" v-on:orderSaved="loadData"/>

    </div>
</template>
<script>

    import { BOverlay, BCard, BButton, BBadge } from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import AddModal from '@/views/TV/Channels/AddModal'
    import OrderModal from '@/views/components/OrderModal'

    export default {
        components: {
            OrderModal,
            AddModal,
            BOverlay,
            BCard,
            BButton,
            BasicTable,
            BBadge
        },
        data() {
            return {

                items: [],
                categories: [],
                dataLoaded: false,

                columns: [
                    {
                        label: 'Logo',
                        displayType: 1,
                        field: 'logo',
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Categories',
                        displayType: 4,
                        field: this.categoriesRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Category'
                        }
                    },
                    {
                        label: 'Adult',
                        displayType: 3,
                        field(rowObj) {
                            return (rowObj.adult) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Enabled',
                        displayType: 3,
                        field(rowObj) {
                            return (rowObj.enabled) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 2,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/tv_channel')
                loadPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const categoryPromise = this.$http.get('/api/management/v1/tv_channel_category')
                categoryPromise.then(function(response) {
                    thisIns.categories = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, categoryPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })

            },
            removeItem(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/tv_channel/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('TV Channel removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            categoriesRenderer(rowObj) {

                if (!rowObj.selected_categories) return ''

                const categoriesMap = []
                const thisIns = this
                rowObj.selected_categories.forEach(function(id) {
                    if (thisIns.categoriesMap[id]) {
                        categoriesMap.push(thisIns.categoriesMap[id].name)
                    } else {
                        categoriesMap.push('Unknown')
                    }

                })
                return categoriesMap.join(', ')

            }

        },
        computed: {
            categoriesMap() {
                if (!this.categories) return {}
                const categories = {}
                this.categories.forEach(function(category) {
                    categories[category.id] = category
                })

                return categories
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
